@import url(https://fonts.googleapis.com/css?family=Roboto:300);

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --prColor: #1f3f6d
}

main{
  font-family: "Roboto", sans-serif;
  background-color: var(--prColor);
}

main, .login-page{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.logo{
  width: 10rem;
}

.form{
  background-color: white;
  padding: 2rem;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
}

.form div{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
  gap: 2rem;
  padding: 1rem;
}

.form div h2, .form div p{
  text-align: center;
}

button{
  height: 3rem;
  width: 100%;
  background: var(--prColor);
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 0.5rem;
}

button:hover{
  opacity: 80%;
}

.form .message {
  margin: 15px 0 0;
  color: rgb(129, 129, 129);
  font-weight: bold;
  font-size: 0.9rem;;
}

.form div ol{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  padding-left: 1.5rem;
}

/* 
main{
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 660px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }
  .form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
  }
  .form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #3d80ad;
    width: 50%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  .form button:hover,.form button:active,.form button:focus {
    background: #1A5EB9;
  }


  .form .message a {
    color: #4CAF50;
    text-decoration: none;
  }
  .form .register-form {
    display: none;
  }
  .container {
    position: relative;
    z-index: 1;
    max-width: 300px;
    margin: 0 auto;
  }
  .container:before, .container:after {
    content: "";
    display: block;
    clear: both;
  }
  .container .info {
    margin: 50px auto;
    text-align: center;
  }
  .container .info h1 {
    margin: 0 0 15px;
    padding: 0;
    font-size: 36px;
    font-weight: 300;
    color: #1a1a1a;
  }
  .container .info span {
    color: #4d4d4d;
    font-size: 12px;
  }
  .container .info span a {
    color: #000000;
    text-decoration: none;
  }
  .container .info span .fa {
    color: #EF3B3A;
  }
  #divleft {
      float: left;
      width: 48%;
      margin-right: 2%;
  }
  
  #divright {
      float: left;
      width: 48%;
      margin-left: 2%;
  }	
  body {
    background: #3d80ad; //fallback for old browsers 
    background: linear-gradient(90deg, rgba(61,128,173,1) 0%, rgba(26,94,185,1) 50%);
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;      
  }
  .espaco {
    margin-top: 30px;
  }
  .form-item {
    text-align: left;
  }
  .botao-acessar {
    width: 100%!important;
  }

  @media(max-width: 700px){
    body{
      background: transparent;
    }
  }
 */
